import { useEffect, useRef } from 'react'
import styles from '../../styles/module/Skill.module.scss'

const Skill = ({ page, setPage }) => {
  // ! hooks
  const sectionElement = useRef()
  useEffect(() => {
    sectionElement.current.onwheel = (e) => {
      console.log(document.body.clientWidth, e.wheelDeltaY, sectionElement.current.scrollTop, sectionElement.current.scrollHeight, sectionElement.current.offsetHeight)
      if (document.body.clientWidth > 1200)
        if (
          e.wheelDeltaY < 0 &&
          Math.ceil(sectionElement.current.offsetHeight +
            sectionElement.current.scrollTop) >=
            sectionElement.current.scrollHeight
        )
          setPage(3)
        else if (e.wheelDeltaY > 0 && sectionElement.current.scrollTop === 0)
          setPage(1)
    }
  }, [])
  return (
    <div
      className='content'
      style={{ transform: `translateY(-${100 * page}%)` }}
      ref={sectionElement}>
      <h2 className='title'>
        <span>S</span>kills
      </h2>
      <div className={styles.skill_wrapper}>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-brands fa-python'></i>
            </div>
            <div>
              <h5>Python</h5>
              <ul>
                <li>Proficient in <b>Tensorflow, Pytorch</b></li>
                <li>Building <b>REST API</b> using Flask</li>
                <li>Data cleansing and preprocessing using <b>Pandas, OpenRefine</b></li>
                <li>Data visualization with <b>Matplotlib</b></li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-solid fa-code'></i>
            </div>
            <div>
              <h5>Other Programming languages</h5>
              <ul>
                <li>Java</li>
                <li>IBM RPG</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-solid fa-database'></i>
            </div>
            <div>
              <h5>Database</h5>
              <ul>
                <li>Highly skilled in <b>SQL</b></li>
                <li>Moderately skilled in <b>MongoDB</b></li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-solid fa-comment-dots'></i>
            </div>
            <div>
              <h5>Language Proficiency</h5>
              <ul>
                <li>IELTS <b>7.5</b> Breakdown - S: <b>8</b>, R: <b>8</b>, L: <b>7.5</b>, W: <b>7</b></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skill
