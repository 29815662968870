import { useEffect, useRef } from 'react'
import styles from '../../styles/module/About.module.scss'

const About = ({ page, setPage }) => {
  // ! hooks
  const sectionElement = useRef()
  useEffect(() => {
    sectionElement.current.onwheel = (e) => {
      if (document.body.clientWidth > 1200)
        if (
          e.wheelDeltaY < 0 &&
          sectionElement.current.offsetHeight +
            sectionElement.current.scrollTop >=
            sectionElement.current.scrollHeight
        )
          setPage(1)
    }
  }, [])
  return (
    <div
      className='content'
      style={{ transform: `translateY(-${100 * page}%)` }}
      ref={sectionElement}>
      <h2 className='title'>
        <span>A</span>bout Me
      </h2>
      <div className={styles.about_wrapper}>
        <div className={styles.about}>
          <h3>I am Mosarrat Rumman, a machine learning researcher</h3>
          <p>
            I have expertise in Machine learning and deep learning specially in the field
            of Computer Vision and Natural Language Processing. I try to stay updated in these fields 
            by reading research papers and trying to implement them by myself. Currently my interest lies 
            on generative models like Diffusion Probabilistic models, Generative Adversarial Networks(GANs) etc.
            I am also interested in Natural Languae generation using transformer based models. However having
            particular interest in biological science, I love to work with medical image analysis. 
          </p>
          <p>
          I have worked for almost 4 years in one of the largest banks in Bangladesh, as a 
          Principal officer in the Application Architect and Development team, where I worked 
          as a senior backend developer and database engineer of the Core Banking System. This job gave 
          me the opportunity to build strong expertise in analyzing the huge database of the 
          Core Banking System and writing complicated SQL queries for various reports and performance 
          analyses. However, while pursuing my Master’s alongside this job, I realized my passion and 
          thrill for research works. I was always full of ideas, but rarely had time to work on them 
          while on this job. I love reading research papers and learning new technologies. Therefore,
          despite having smooth career growth (2 promotions in 3 years), I left the job to pursue my dream 
          of being a researcher.
          </p>
        </div>
        <div className={styles.featured_wrapper}>
          <div className={styles.featured_box}>
            <h1>3+</h1>
            <p>Years Experience</p>
          </div>
          <div className={styles.featured_box}>
            <h1>2</h1>
            <p>Published Papers</p>
          </div>
          <div className={styles.featured_box}>
            <h1>10+</h1>
            <p>Project Done</p>
          </div>
          <div className={styles.featured_box}>
            <h1>7+</h1>
            <p>Awards and scholarships</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
