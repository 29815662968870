import styles from '../../styles/module/Aside.module.scss'
const Aside = () => {
  return (
    <>
      <aside className={styles.aside}>
        <div className={styles.user_image}>
          <img
            src='mosarrat-rumman.jpg'
            alt='Mosarrat Rumman'
          />
          <h3>Mosarrat Rumman</h3>
          <h6>Computer Vision, Application Architect</h6>
        </div>
        <div className={styles.user_info}>
          <div className={styles.resume_btn}>
            <a 
              href="https://drive.google.com/file/d/19BSnEOPpTi9qOu_5aO0RU25xutITwJ-w/view?usp=sharing"
              target='_blank'
              rel='noopener noreferrer'>
              <button>Download CV</button>
            </a>
          </div>
          <div className={styles.social}>
            <h5>Follow Me On</h5>
            <ul>
              <li>
                <a
                  href='https://github.com/mosarrat28/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-github'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/in/isaba-rumman/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-linkedin-in'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://twitter.com/_isaba_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-twitter'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
      <header className={styles.header}>
        <div className={styles.header_wrapper}>
          <div className={styles.left}>
            <h2>Mosarrat Rumman</h2>
          </div>
          <div className={styles.right}>
            <ul className={styles.social}>
              <li>
                <a
                  href=''
                  target='_blank'
                  rel='noopener noreferrer'>
                  <div className={styles.resume_btn}>
                    <button>Download CV</button>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href='https://github.com/mosarrat28/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-github'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://www.linkedin.com/in/isaba-rumman/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-linkedin-in'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://twitter.com/_isaba_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fa-brands fa-twitter'></i>
                </a>
              </li>
            </ul>
            <div className={styles.menu_btn}>
              <i className='fa-solid fa-bars'></i>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Aside
