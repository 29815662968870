import { useEffect, useRef } from 'react'
import styles from '../../styles/module/Contact.module.scss'

const Contact = ({ page, setPage }) => {
  // ! hooks
  const sectionElement = useRef()
  useEffect(() => {
    sectionElement.current.onwheel = (e) => {
      if (document.body.clientWidth > 1200)
        if (e.wheelDeltaY > 0 && sectionElement.current.scrollTop === 0)
          setPage(3)
    }
  }, [])
  return (
    <div
      className='content'
      style={{ transform: `translateY(-${100 * page}%)` }}
      ref={sectionElement}>
      <h2 className='title'>
        <span>C</span>ontact
      </h2>
      <div className={styles.contact_wrapper}>
      {/*  <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis alias
          quaerat soluta eum autem perspiciatis voluptates sunt aut a molestiae.
        </p>
        <form>
          <div>
            <input type='text' placeholder='Enter full name' />
            <input type='email' placeholder='Enter email address' />
          </div>
          <textarea
            rows='6'
            placeholder='Tell us more about your needs...'></textarea>
          <button>Send Message</button>
  </form> */}
        {/* <div className={styles.follow}>
          <h5>Follow Me On</h5>
          <ul>
            <li>
              <i className='fa-brands fa-facebook-f'></i>
            </li>
            <li>
              <i className='fa-brands fa-linkedin'></i>
            </li>
            <li>
              <i className='fa-brands fa-github'></i>
            </li>
          </ul>
  </div> */}
        <div className={styles.more}>
          <h5>More Information</h5>
          <ul>
            <li>
              <i className='fa-solid fa-location-dot'></i> Dhaka, Bangladesh
            </li>
            <li>
              <i className='fa-solid fa-envelope'></i>{' '}
              <a href='mailto:msayem089@gmail.com'>mosarrat.ru@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Contact
