import { useState } from 'react'
import About from './components/about'
import Aside from './components/common/Aside'
import Contact from './components/contact'
import Education from './components/education'
import Skill from './components/skill'
import Research from './components/research'

function App() {
  const [page, setPage] = useState(0)
  return (
    <div className='App'>
      <div className='container'>
        <div className='card-wrapper'>
          <Aside />
          <ul className='floating-link'>
            <li>
              <span
                onClick={() => setPage(0)}
                className={page === 0 ? 'active' : ''}>
                <i className='fa-solid fa-user'></i>
              </span>
            </li>
            <li>
              <span
                onClick={() => setPage(1)}
                className={page === 1 ? 'active' : ''}>
                <i className='fa-solid fa-pencil'></i>
              </span>
            </li>
            <li>
              <span
                onClick={() => setPage(2)}
                className={page === 2 ? 'active' : ''}>
                <i className='fa-solid fa-brain'></i>
              </span>
            </li>
            <li>
              <span
                onClick={() => setPage(3)}
                className={page === 3 ? 'active' : ''}>
                <i className='fa-solid fa-user-graduate'></i>
              </span>
            </li>
            <li>
              <span
                onClick={() => setPage(4)}
                className={page === 4 ? 'active' : ''}>
                <i className='fa-solid fa-id-badge'></i>
              </span>
            </li>
          </ul>
          <div className='content-wrapper'>
            <About page={page} setPage={setPage} />
            <Research page={page} setPage={setPage} />
            <Skill page={page} setPage={setPage} />
            <Education page={page} setPage={setPage} />   
            <Contact page={page} setPage={setPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
