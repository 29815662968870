import { useEffect, useRef } from 'react'
import styles from '../../styles/module/Research.module.scss'

const Research = ({ page, setPage }) => {
  // ! hooks
  const sectionElement = useRef()
  useEffect(() => {
    sectionElement.current.onwheel = (e) => {
      console.log(document.body.clientWidth, e.wheelDeltaY, sectionElement.current.scrollTop, sectionElement.current.scrollHeight, sectionElement.current.offsetHeight)
      if (document.body.clientWidth > 1200)
        if (
          e.wheelDeltaY < 0 &&
          Math.ceil(sectionElement.current.offsetHeight +
            sectionElement.current.scrollTop) >=
            sectionElement.current.scrollHeight
        )
          setPage(2)
        else if (e.wheelDeltaY > 0 && sectionElement.current.scrollTop === 0)
          setPage(0)
    }
    // sectionElement.current.scrollTo(0, 0)
  }, [])
  return (
    <div
      className='content'
      style={{ transform: `translateY(-${100 * page}%)` }}
      ref={sectionElement}>
      <h2 className='title'>
        <span>R</span>esearch & Publications
      </h2>
      <p>
        I try to share what I have learn. It helps me a lot. Hopefully you will
        be beneficial.
      </p>
      <div className={styles.blog_wrapper}>
        <div className={styles.blog_item}>
          <div>
            <div className={styles.image}>
              <img
                src='p11.png'
                alt=''
              />
              <img
                src='p12.png'
                alt=''
              />
            </div>
            <div>
              <div className={styles.info}>
                <h5>
                  <a href='#'>Early detection of Parkinson's disease using image processing and artificial neural network</a>
                </h5>
                <p>
                In this paper, SPECT images of early diagnosed patients and healthy controls are collected from PPMI database.
                Instead of using a computational heavy CNN, a simple approach is used where only the region of Interest, 
                i.e,. the dopeminergic region is considered and a single perceptron is trained with the are of ROI. The model
                achieved an accuracy of 94%. 
                <b>The paper was presented in 2018 Joint 7th International Conference on Informatics, Electronics & Vision (ICIEV) 
                and 2018 2nd International Conference on Imaging, Vision & Pattern Recognition (icIVPR) and is publised in IEEE Xplore. 
                The paper has 21 citations till now. </b>
                </p>
                <a href="https://www.researchgate.net/publication/333817461_Early_detection_of_Parkinson's_disease_using_image_processing_and_artificial_neural_network" target='_blank' className={styles.read_btn}>
                  Link to paper
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.blog_item}>
          <div>
            <div className={styles.image}>
              <img
                src='p21.png'
                alt=''
              />
              <img
                src='p22.png'
                alt=''
              />
            </div>
            <div>
              <div className={styles.info}>
                <h5>
                  <a href='#'>Best Paper Award Winner - Parts of Speech Tagging in Bangla Sentences using Supervised Learning:
                   A Performance Comparison between Viterbi and Bidirectional-LSTM Models</a>
                </h5>
                <p>
                  Parts of speech (POS) tagging is done on Bangla - which is a low resource language. 
                  Two POS taggers were built: 1. Using a Hidden Markov Model. 2. Using deep learning model - biLSTM.
                  The performance of both the models were analyzed on mutliple variables. It can be inferred from the
                  results that increasing the size of dataset has greater positive impact on the performace of Bi-LSTM 
                  model than on the HMM model.
                  <b>The paper is presented in 2021 IEEE International Women in Engineering (WIE) Conference on Electrical 
                  and Computer Engineering (WIECON-ECE). </b>
                </p>
                <a href="https://www.researchgate.net/publication/362124085_Parts_of_Speech_Tagging_in_Bangla_Sentences_using_Supervised_Learning_A_Performance_Comparison_between_Viterbi_and_Bidirectional-LSTM_Models" target='_blank' className={styles.read_btn}>
                  Link to paper
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Research
