import { useEffect, useRef } from 'react'
import styles from '../../styles/module/Education.module.scss'

const Education = ({ page, setPage }) => {
  // ! hooks
  const sectionElement = useRef()
  useEffect(() => {
    sectionElement.current.onwheel = (e) => {
      if (document.body.clientWidth > 1200)
        if (
          e.wheelDeltaY < 0 &&
          sectionElement.current.offsetHeight +
            sectionElement.current.scrollTop >=
            sectionElement.current.scrollHeight
        )
          setPage(4)
        else if (e.wheelDeltaY > 0 && sectionElement.current.scrollTop === 0)
          setPage(2)
    }
  }, [])
  return (
    <div
      className='content'
      style={{ transform: `translateY(-${100 * page}%)` }}
      ref={sectionElement}>
      <h2 className='title'>
        <span>E</span>ducation
      </h2>
      <div className={styles.skill_wrapper}>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-solid fa-graduation-cap'></i>
            </div>
            <div className={styles.degree_item}>
              <h5>MSc in Computer Science</h5>
              <p>Brac University</p>
              <p>CGPA: <b>3.95</b> / 4</p>
              <b>Projects:</b>
              <ul>
                <li>Generating Fashion images using Conditional DC-GAN according to given cloth
category</li>
                <li>Extractive summarizer using BERT</li>
              </ul>
            </div>  
          </div>
        </div>
        <div className={styles.skill_item}>
          <div>
            <div className={styles.icon}>
              <i className='fa-solid fa-graduation-cap'></i>
            </div>
            <div className={styles.degree_item}>
              <h5>BSc in Computer Science & Engineering</h5>
              <p>Brac University</p>
              <p>CGPA: <b>3.51</b> / 4</p>
              <b>Projects:</b>
              <ul>
                <li>IoT based weather station (Arduino, sensors and google firebase)</li>
                <li>Object detection for blind people (Raspberry pi, sensors, Google Vision API)</li>
                <li>Hospital Management System Web Application (PHP, MySQL, HTML, CSS,
Bootstrap)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Education
